import React from "react";
import { SVGComponent } from "../../types";

export default ({ ...props }: SVGComponent) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <rect width={512} height={512} rx={76.8} fill="#fff" />
      <path
        d="M456.42 339.58c-8 10.06-27.5 17.23-27.5 17.23L283.63 409v-38.49l106.92-38.09c12.13-4.35 14-10.5 4.13-13.72s-27.66-2.31-39.81 2.06l-71.24 25.09v-39.94l4.1-1.39s20.59-7.29 49.54-10.5 64.39.44 92.22 11c31.36 9.9 34.89 24.5 26.93 34.56zm-159-65.53v-98.43c0-11.55-2.13-22.2-13-25.21-8.3-2.66-13.46 5.05-13.46 16.6v246.48l-66.47-21.1V98.51c28.26 5.25 69.44 17.65 91.57 25.11C352.42 143 371.5 167 371.5 221.2c0 52.8-32.61 72.85-74.04 52.85zM82.58 366.49c-32.19-9.06-37.55-28-22.87-38.84C73.27 317.6 96.33 310 96.33 310l95.31-33.89v38.64l-68.59 24.54c-12.11 4.35-14 10.5-4.13 13.73s27.69 2.31 39.82-2.05l32.9-11.94v34.57l-6.56 1.1a215.66 215.66 0 01-102.5-8.25zm352.67 15.31a15.57 15.57 0 11-15.56 15.58 15.58 15.58 0 0115.56-15.58zm-12.94 15.58a13 13 0 0012.94 12.94 13 13 0 0012.94-12.94 12.94 12.94 0 10-25.88 0zM440 389a4.25 4.25 0 012.56 4.25 4.77 4.77 0 01-.67 2.64 4.67 4.67 0 01-1.31 1.27 3.68 3.68 0 011 .86 4 4 0 01.61 2.3l.08 2.21a6 6 0 00.16 1.33 1 1 0 00.49.75l.24.11v1h-3.36l-.11-.2a2.77 2.77 0 01-.22-.67 8.8 8.8 0 01-.12-1.35l-.14-2.76a2 2 0 00-1-1.86 4.73 4.73 0 00-1.86-.26h-4.72v7.1h-3V388.4h8.05a7.81 7.81 0 013.32.6zm-8.31 7h4.8a4.11 4.11 0 002.28-.54 2.28 2.28 0 00.75-2 2.08 2.08 0 00-1.07-2.08 3.92 3.92 0 00-1.66-.31h-5.1V396z"
        fill="#1f1f1f"
        fillRule="evenodd"
      />
    </svg>
  );
}