import React from "react";
import { SVGComponent } from "../../types";

export default ({ ...props }: SVGComponent) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <rect width={512} height={512} rx={76.8} fill="#e60012" />
      <path
        d="M90.22 311.31c-35.21 0-58.73-25-58.73-55.21 0-30.25 23.58-55.49 58.68-55.41h331.66c35.1-.08 58.68 25.16 58.68 55.41 0 30.25-23.52 55.2-58.73 55.21zm.11-97c-27.87 0-45 18.68-45 41.73 0 23 17 41.82 45 41.72h331.34c28 .09 45-18.68 45-41.72 0-23-17.11-41.67-45-41.73zm349.17 19.44c2.53 0 3.79.86 3.79 2.56a2.36 2.36 0 01-1.94 2.35l2 4h-2.41l-1.7-3.64h-1.05v3.64H436v-8.88zm-.2-3.47a8 8 0 108 8 8 8 0 00-8-8zm6.48 8a6.48 6.48 0 11-6.48-6.48 6.48 6.48 0 016.48 6.5zm-7.6-.61h1.05c1.24 0 1.83-.37 1.83-1.26s-.63-1.32-1.93-1.32h-.95zm-181.13 21.7c0-3 0-5.06 1-6.79a6.38 6.38 0 015.51-3.16 6.38 6.38 0 015.51 3.16c1 1.73 1 3.76 1 6.8zm13 10.44v2.1c0 6.33-4.17 7.74-6.48 7.74s-6.55-1.41-6.55-7.74v-7.15h29.5c0-10.73-10.37-19.63-23.11-19.63s-23.06 8.7-23.06 19.44S250.7 284 263.44 284c10.57 0 19.49-6 22.22-14.2zm49.27-24.11c5.86-.17 17.74 3.66 17.65 17.37v18.85h-15.55v-22.73c0-3-2.89-6.37-7.16-6.37s-7.43 3.36-7.43 6.37v22.75H291.3v-34.63h15.54v3.64a18.83 18.83 0 0112.48-5.22zm-127.26 0c5.86-.17 17.74 3.66 17.65 17.37v18.85h-15.55v-22.73c0-3-2.89-6.37-7.16-6.37s-7.43 3.36-7.43 6.37v22.75H164v-34.63h15.56v3.64a18.83 18.83 0 0112.48-5.22zm178.66-15.85h15.46V282h-15.47v-2a18.78 18.78 0 01-18 1.3A18.46 18.46 0 01342 264.08c0-9.14 8.63-19.13 19.91-18.14a21 21 0 018.86 3.09v-19.16zm.12 34.59v-6.19c0-5.36-3.7-6.72-6.13-6.72s-6.15 1.36-6.15 6.72v12.33c0 5.35 3.65 6.75 6.14 6.75s6.14-1.4 6.14-6.75v-6.14zm49.61-.1v7.33c0 5.52-3.27 7.8-6.4 7.8s-6.42-2.28-6.42-7.8v-14.69c0-5.49 3.28-7.75 6.4-7.75s6.4 2.26 6.4 7.75v7.37zM414.17 245c-12.64 0-22.88 8.71-22.88 19.45s10.25 19.44 22.88 19.44 22.89-8.7 22.89-19.44S426.81 245 414.17 245zm-274.45-4.44v-10.7h15.69v10.65zm0 41.44v-34.68h15.66V282zm78.56 0v-34.61h-8.51v-5.14h8.52v-7H234v7h8.47v5.16H234V282zM76 282v-52.13h16.26l22.44 36.32v-36.32h16.1V282h-16.08l-22.57-36.37V282z"
        fill="#fff"
      />
    </svg>
  );
}