import React from "react";
import { SVGComponent } from "../../types";

export default ({ ...props }: SVGComponent) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Linux"
      viewBox="0 0 512 512"
      fill="#333"
      {...props}
    >
      <rect width={512} height={512} rx="15%" fill="#fff" />
      <path d="M192 206c-6 14-48 58-44 102 16 184 72 60 156 106 0 0 150-84 30-220-34-48-4-86-26-118s-60-34-88-4 12 74-28 134" />
      <path
        d="M340 298s18-36-16-62c32 34 12 64 12 64h-6c-2-70-20-32-46-156 30-34-28-64-28-8h-18c2-48-40-24-16 10-2 74-46 104-46 156-14-36 12-64 12-64s-36 30-14 74 62 34 34 54c44 30 112 10 110-54 2-16 44-10 48-6s-6-8-26-8M228 142c-14-4-10-22-4-22s16 14 4 22m38 2c-10-14-2-28 8-26s10 26-8 26"
        fill="#eee"
      />
      <g fill="#fc2" stroke="#333">
        <path
          d="M174 318l42 60c22 14 10 70-50 42-34-10-62-8-66-26s8-20 6-28c-8-44 28-22 38-44s10-32 30-4m224 28c-8-12 0-34-28-32-12 24-46 48-48 0-20 0-6 48-14 70-18 54 34 58 56 32l52-36c4-6 10-12-18-34M214 162c-6-12 22-28 32-28s24 8 38 12 8 18 4 20-26 20-42 20-20-16-32-24M214 160c16 12 34 22 70-6"
          strokeWidth={2}
        />
      </g>
      <path d="M236 148c-4 0 2-4 4-2m14 2c2-2-2-4-6-2" />
    </svg>
  );
}