import React from "react";
import { SVGComponent } from "../../types";

export default ({ ...props }: SVGComponent) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Debian"
      viewBox="0 0 512 512"
      {...props}
    >
      <rect width={512} height={512} rx="15%" fill="#fff" />
      <path
        d="M388 225c.4 11-3 17-6 27-10 3-8 14-9 17-6 3-35 34-25 17-10 7-8 10-24 15l-.4-1c-39 18-94-18-94-68-.4 3-1 2-2 3-1-56 64-88 102-54-25-29-78-31-96 4-10 6-12 26-16 29-5 88 75 145 150 78 8-6 16-16 19-17-3 5.7.6 2-2 7 6-10 1-31 12-16.2.8-19 22-58 11-23 3-12 1-36 6-14-3-12 3-21 5-28-6-15-18-43-4-23-29-60 1-35 12 5-1-10.81-4.9-21.2-8-31 2 1-4-22 3-6-9-33-38-62-65-78 18 18-52-15-69-21 12 7-61 7-20.5.8-14-1-32 4-15-5-10.8.6-25 6-21 1-21 11-85 54-67 31-23 21-37 36-49 64-79 111-2 278 123 325 11 4 29 4 44 4-17-5-20-2-37-8-12-5-15-12-24-20-.7 8-24-.5-16-10-5-.4-14-9-17-14-9 2-17-14-17-20-1 3-37-33-16-23-5-5-12-6-6-12-5-14-18-20-3-15-22-15-35-39-25-40-5-6-5-11-4-19-17-37 1-83 14-114-21 2 38-50 54-50-2-8 11-18 27-23-1-1 56-26 32-8 67-22 141 36 145 96 4 1-.5 45-1 48"
        fill="#d70751"
      />
    </svg>
  );
}