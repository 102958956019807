import React from "react";
import { SVGComponent } from "../../types";

export default ({ ...props }: SVGComponent) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <rect width={512} height={512} rx={76.8} fill="#fff" />
      <path
        d="M457.78 256c0-111.44-90.34-201.79-201.78-201.79S54.3 144.49 54.22 255.87V412a45.86 45.86 0 0045.87 45.76h156c111.4 0 201.7-90.36 201.7-201.77"
        fill="#294172"
      />
      <g fill="none" stroke="#3c6eb4" strokeWidth={29.21}>
        <path d="M170.55 269h72.52v72.52A72.52 72.52 0 11170.55 269z" />
        <path d="M315.24 269.17h-72.52v-72.52a72.52 72.52 0 1172.52 72.52z" />
      </g>
      <g fill="#fff">
        <path d="M265.26 247v94.5a94.71 94.71 0 01-94.71 94.7 75.36 75.36 0 01-20.92-2.81c-10.71-2.81-19.46-11.59-19.46-21.8 0-12.35 9-21.33 22.35-21.33 6.38 0 8.69 1.23 18 1.23a50 50 0 0050-49.91v-43.49a7 7 0 00-7.06-7h-32.82a22 22 0 11.23-44.09" />
        <path d="M220.54 291.25v-94.5a94.71 94.71 0 0194.7-94.7 75.27 75.27 0 0120.92 2.82c10.71 2.8 19.47 11.58 19.47 21.79 0 12.35-9 21.33-22.36 21.33-6.37 0-8.69-1.22-18-1.22a50 50 0 00-50 49.91v43.43a7.07 7.07 0 007.07 7h32.83a22 22 0 11-.23 44.09" />
      </g>
      <path
        d="M423 422.86v-8.39l-3.84 8.79-3.73-8.79v8.39h-2.15v-13.24h2.26l3.69 8.46 3.63-8.46h2.28v13.24zm-15.67-11v11h-2.24v-11h-3.77v-2.22h9.78v2.22"
        fill="#3c6eb4"
      />
    </svg>
  );
}